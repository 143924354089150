<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card v-if="editData" no-body>
				<b-card-header>
					<h4>{{ rendPageTitle }}</h4>
				</b-card-header>
				<b-card-body>
					<b-row>
						<b-col cols="12" xl="12">
							<b-row>
								<b-col md="12" lg="9">
									<b-row>
										<b-col sm="12">
											<b-card v-if="editData">
												<h6>{{ FormMSG(12131, 'From To Address') }}</h6>
												<div class="selectors-holder">
													<AddressMaker
														:disabled="isEditionDisabled"
														type="from"
														:edit-data="editData"
														@change="handleFromAddressChange"
													/>
													<AddressMaker
														:disabled="isEditionDisabled"
														style="margin-top: 20px"
														type="to"
														:edit-data="editData"
														@change="handleToAddressChange"
													/>
												</div>
											</b-card>
										</b-col>
									</b-row>
									<b-row v-if="$has(editData)">
										<b-col v-if="hasDirection" sm="12">
											<DateMakerCard
												:disabled="isEditionDisabled"
												:edit-data="editData"
												@change="handleDateChange"
												@restriction-ways-change="handleRestrictionWaysChange"
												@mean-change="handleTransportMean"
											/>

											<ShowTransportEstimationResult
												:loading="isDurationEstimationLoading"
												:is-diff-duration-calcul="isDurationCalculatedBasedOnCarButNotIsDeferentMean"
												:departure-time="editData.departureTime"
												:arrival-time="editData.arrivalTime"
												:kg-co-two="editData.kgCoTwo"
												:duration-estimation="durationEstimation"
											/>
										</b-col>
										<b-col v-else>
											{{ FormMSG(786, 'No direction') }}
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<DriverAndPassengerCard
												:disabled="isEditionDisabled"
												:transport="editData"
												@driver-change="handleDriverChange"
												@car-change="handleCarChange"
											/>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<VehiculesCard :disabled="isEditionDisabled" :edit-data="editData" @change="handleCarChange" />
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-form-textarea
												id="transport-extraInfo"
												v-model="transportPaylaod.extraInfo"
												:disabled="isEditionDisabled"
												:placeholder="FormMSG(342342, 'Transport\'s extra info...')"
												rows="3"
												required
											/>
										</b-col>
									</b-row>
								</b-col>

								<b-col md="12" lg="3">
									<b-row>
										<b-col md="12">
											<b-form-group>
												<b-button class="full-width-on-mobile" variant="success" size="lg" @click="goToTransports">
													{{ FormMSG(21, 'Back to transports') }}
													<i class="icon-action-undo" />
												</b-button>
											</b-form-group>
										</b-col>
										<b-col md="12">
											<b-form-group>
												<b-button
													class="full-width-on-mobile"
													variant="primary"
													size="lg"
													:disabled="disableSave || isEditionDisabled"
													@click="updtTransport"
												>
													{{ FormMSG(2123, 'Save modifications') }}
												</b-button>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
		</transition>
	</errorContainer>
</template>

<script>
import moment from 'moment';
import { objReducer, isNil, mergeObjIf } from '@/shared/utils';
import { fetchDistance } from '~helpers';
import { getAddress } from '@/cruds/address.crud';
import { GetKmGreenImpact } from '@/cruds/carbon.crud';
import { quickSave, getTravelModeFromTransportMean, isStarted, isArrived, setDurationToDate } from '@/modules/transports/utils';

import { getTransport, updatedTransport } from '@/cruds/transports.crud';
import languageMessages from '@/mixins/languageMessages';

import DateMakerCard from '@/modules/transports/components/DateMakerCard';
import DriverAndPassengerCard from '@/modules/transports/components/DriverAndPassengerCard';
import AddressMaker from '@/modules/transports/components/AddressMaker';
import VehiculesCard from '@/modules/transports/components/VehiculesCard';
import ShowTransportEstimationResult from '@/modules/transports/components/ShowTransportEstimationResult';

const now = new Date();
const transportPaylaodDefault = { extraInfo: null, kgCoTwo: 0, transportMean: '' };
const transportKeys = Object.keys(transportPaylaodDefault);

export default {
	name: 'TransportsFocusComponentPage',
	components: {
		AddressMaker,
		VehiculesCard,
		DateMakerCard,
		ShowTransportEstimationResult,
		DriverAndPassengerCard
	},
	mixins: [languageMessages],
	data() {
		return {
			noDate: '0001-01-01T00:00:00Z',
			erreur: {},
			warning: '',
			editData: null,
			disableSave: false,
			transportPaylaod: Object.assign({}, transportPaylaodDefault),
			distancePayload: {
				from: null,
				to: null,
				departureTime: moment(now).format(),
				km: 0,
				type: 0,
				arrivalTime: null,
				avoidHighways: false,
				avoidTolls: false
			},

			isDurationEstimationLoading: false,
			durationEstimation: null,

			estimatedDateTimeValue: {
				field: 'departureTime',
				value: null
			},

			dateTimeField: 'arrivalTime',

			hasDates: false,
			isDurationCalculatedBasedOnCarButNotIsDeferentMean: false
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendPageTitle() {
			return `${this.FormMSG(11, 'Transport:')} ${this.editData.id}`;
		},

		/**
		 * @return {Boolean}
		 */
		hasDirection() {
			const { from, to } = this.distancePayload;
			return from !== 0 && to !== 0;
		},

		optionsForTransportMean() {
			let res = this.FormMenu(905); // 1124 is without planes

			res = res.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return res;
		},

		/**
		 * @return {Boolean}
		 */
		isEditionDisabled() {
			const _e = this.editData;
			if (isNil(_e)) return false;
			return isStarted(_e) || isArrived(_e);
		}
	},
	watch: {
		distancePayload: {
			/**
			 * @param {Object}
			 */
			handler(direction) {
				if (isNil(direction.from) || isNil(direction.to)) return;
				if (direction.from === 0 || direction.to === 0) return;
				this.getCommuteTime(direction);
			},
			deep: true
		}
	},
	async created() {
		await this.initTransport();
		this.initDistancePayload();
	},
	methods: {
		async initTransport() {
			this.editData = await getTransport(this.$route.params.id);
			this.transportPaylaod = objReducer(transportKeys, this.editData);

			if (this.editData.departureTime !== this.noDate) {
				this.hasDates = true;
			}
		},

		/**
		 * @param {Object} payload
		 */
		handleRestrictionWaysChange({ avoidHighways, avoidTolls }) {
			this.distancePayload = { ...this.distancePayload, avoidHighways, avoidTolls };
		},

		initDistancePayload() {
			if (isNil(this.editData)) return;
			this.distancePayload = {
				from: this.editData.fromAddressId,
				to: this.editData.toAddressId,
				km: this.editData.km,
				type: this.editData.type,
				departureTime: this.editData.departureTime
			};
		},

		async updtTransport() {
			try {
				await updatedTransport(this.editData.id, this.transportPaylaod);
				this.$bvToast.toast(this.FormMSG(7959689, 'Transport saved successfully'), {
					title: this.FormMSG(56767567, 'Success'),
					variant: 'success',
					solid: true,
					toaster: 'b-toaster-top-center'
				});
			} catch (e) {
				this.$bvToast.toast(this.FormMSG(689689689, 'Saving transport failed'), {
					title: this.FormMSG(908908, 'Something went wrong'),
					variant: 'success',
					solid: true,
					toaster: 'b-toaster-top-center'
				});
			}
		},

		/**
		 * @param {Object} key
		 * @return {Object}
		 */
		async quickSaveTransportElement(payload) {
			const res = quickSave(this.editData.id, payload);
			return res;
		},

		goToTransports() {
			this.$router.push({ name: 'Transports list' });
		},

		async handleDriverChange(driverId) {
			if (isNil(driverId)) return;
			await this.quickSaveTransportElement({ userId: parseInt(driverId) });
		},

		async handleCarChange(vehiculeId) {
			if (isNil(vehiculeId)) return;
			await this.quickSaveTransportElement({ vehiculeId: parseInt(vehiculeId) });
		},

		async handleTransportMean(transportMean) {
			if (isNil(transportMean)) return;
			this.transportPaylaod = { ...this.transportPaylaod, transportMean };
		},

		/**
		 * @param {Object} change
		 */
		async handleFromAddressChange(change) {
			const oposit = {
				fromUserId: 'fromLocationId',
				fromLocationId: 'fromUserId'
			};
			const payload = mergeObjIf(
				{
					fromUserId: 0,
					fromLocationId: 0,
					fromAddressId: change.addressId
				},
				!isNil(change.key),
				{
					[change.key]: change.value,
					[oposit[change.key]]: 0
				}
			);
			const res = await this.quickSaveTransportElement(payload);
			this.editData = Object.assign(this.editData, res);
			this.distancePayload.from = res.fromAddressId;
		},

		/**
		 * @param {Object} change
		 */
		async handleToAddressChange(change) {
			const oposit = {
				toUserId: 'toLocationId',
				toLocationId: 'toUserId'
			};
			const payload = mergeObjIf(
				{
					toUserId: 0,
					toLocationId: 0,
					toAddressId: change.addressId
				},
				!isNil(change.key),
				{
					[change.key]: change.value,
					[oposit[change.key]]: 0
				}
			);
			const res = await this.quickSaveTransportElement(payload);
			this.editData = Object.assign(this.editData, res);
			this.distancePayload.to = res.toAddressId;
		},

		/**
		 * @param {Object} payload - dateFieldsPayload
		 */
		async handleDateChange(payload) {
			if (!['departure', 'arrival'].includes(payload.timeType)) return;

			this.checkIfDurationCalculDiffBase(payload.type);

			this.dateTimeField = payload.timeType === 'arrival' ? 'arrivalTime' : 'departureTime';

			const value = moment(payload.date + ' ' + payload.time).format();

			const res = await this.quickSaveTransportElement({
				[this.dateTimeField]: value,
				depArrStd: payload.timeType === 'arrival' ? 1 : 0,
				type: parseInt(payload.type)
			});

			this.distancePayload = {
				...this.distancePayload,
				type: parseInt(payload.type),
				[this.dateTimeField]: res[this.dateTimeField]
			};
			this.editData = {
				...this.editData,
				[this.dateTimeField]: res[this.dateTimeField]
			};
		},

		async getCommuteTime({ from: departLocation, to: arriveLocation }) {
			if (isNil(departLocation) || isNil(arriveLocation)) return;

			this.isDurationEstimationLoading = true;

			const from = await getAddress(departLocation);
			const to = await getAddress(arriveLocation);

			const travel = getTravelModeFromTransportMean(this.distancePayload.type);

			try {
				const distancePayload = {
					from,
					to,
					...travel,
					avoidHighways: this.distancePayload.avoidHighways || false,
					avoidTolls: this.distancePayload.avoidTolls || false,
					[this.dateTimeField]: this.distancePayload[this.dateTimeField]
				};

				const res = await fetchDistance(distancePayload);

				this.durationEstimation = {
					status: 'success',
					estimation: res
				};
				this.calculDurationTime(res);
			} catch (e) {
				this.durationEstimation = {
					status: 'error',
					message: e,
					estimation: null
				};
			}

			this.isDurationEstimationLoading = false;
		},

		/**
		 * @param {Object} res - googleEstimationRes
		 */
		async calculDurationTime(res) {
			const elem = res.rows[0].elements[0];

			if (isNil(elem.duration)) return;
			const durationInSeconds = elem.duration.value;
			const distanceInKm = elem.distance.value / 1000;

			const kgCoTwo = distanceInKm > 0 ? await GetKmGreenImpact(distanceInKm, parseInt(this.distancePayload.type)) : 0;

			const estimatedDepartureTime =
				this.dateTimeField === 'arrivalTime'
					? setDurationToDate(this.distancePayload.arrivalTime, durationInSeconds, { unit: 'seconds', type: 'remove' })
					: this.distancePayload.departureTime;

			const estimatedArrivalTime =
				this.dateTimeField === 'arrivalTime'
					? this.distancePayload.arrivalTime
					: setDurationToDate(this.distancePayload.departureTime, durationInSeconds);

			const update = await this.quickSaveTransportElement({
				departureTime: estimatedDepartureTime,
				arrivalTime: estimatedArrivalTime,
				eta: estimatedArrivalTime,
				km: distanceInKm,
				kgCoTwo
			});

			this.editData = {
				...this.editData,
				// [opositField]: update[opositField],
				departureTime: update.departureTime,
				arrivalTime: update.arrivalTime,
				km: distanceInKm,
				kgCoTwo
			};
			this.estimatedArrival = estimatedArrivalTime;
		},

		/**
		 * Just to warn that the calculated duration is not based on the mode of transport selected
		 *
		 * @param {Number}
		 */
		checkIfDurationCalculDiffBase(value) {
			const __k = [4, 7, 0, 1, 2, 9, 3];
			this.isDurationCalculatedBasedOnCarButNotIsDeferentMean = !__k.includes(value);
		}
	}
};
</script>
